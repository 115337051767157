@use 'core';

body {
	margin: 0 !important;
}
.titleConfirmTRF{
    margin-top: 30px;
    font-size: 36px;
    color: core.$primary-color;
    text-align: center !important;
    
}
.imageConfirmTRF{
    margin-top: 30px;
    text-align: center !important;
}
.tag-nexus-logo{
	position: absolute;
	top: 0;
	left: calc(50% - 130px);
    img {
        width: auto;
        height: auto;
        &:first-child{
            margin-right: 20px;
        }
   
    }
}
.tag-xhub-logo{
	position: absolute;
	top: 0;
	left: calc(50% - 130px);
    margin-left: 165px;
    img {
        width: auto;
        height: auto;
			
    }
}

.container-login{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-image: url("../images/01-login-background-1024x1080.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-bottom: -48px;
    .rnc__base{
        .rnc__notification-title,
        .rnc__notification-message{
            font-family: core.$font-stack;
        }
        // .rnc__notification-timer{}
    }
    .form-login{
        label {
            display: block;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            color: #505050;
            font-weight: bold;
            font-size: 16px;
        }
        input{
            &[type="text"] {
                display: block;
                width: calc(100% - 14px);
                border: none;
                border-radius: 5px;
                margin-top: 6px;
                margin-bottom: 10px;
                height: 30px;
                padding: 2px 6px;
                text-align: center;
                background-color: #fff;
                color: #585b5c;
                opacity: .6;
                font-size: 16px;
                min-width: 300px;
            }
            &:focus-visible {
                outline: none;
            }
        }
        img.image-login {
            text-align: center;
            margin: auto;
            display: block;
        }
        .btn {
            display: block;
            width: 300px;
            height: 40px;
            border-radius: 10px;
            background-color: core.$primary-color;
            border: none;
            font-family: 'Roboto';
            margin: 10px 0;
            color: core.$secundary-color;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            box-shadow: none;
            appearance: none;
            -webkit-appearance: none;
            &.btn-login{
                background-color: core.$primary-color;
                color: core.$secundary-color;
            }
            &.btn-go-back{
                background-color: core.$primary-color;
                color: core.$secundary-color;
                &.MuiButton-root{
                    text-transform: capitalize;
                    &::after{
                        content: "";
                        position: absolute;
                        top: calc(50% + -5px);
                        left: calc(50% + 40px);
                        width: 0;
                        height: 0;
                        border-left: 7px core.$secundary-color;
                        border-right: 7px core.$secundary-color;
                        border-top: 8px solid core.$secundary-color;
                    }
                }
            }
            &.btn-click-here{
                background-color: transparent;
                color: core.$primary-color;
                font-size: 14px;
                font-weight: 400;
                u{
                    font-weight: 600;
                }
            }
        }
        .MuiCircularProgress-root{
            display: block;
            text-align: center;
            margin: auto;
        }
    }
}
@media (min-width: 1024px)
{
	.drawer-login-form .MuiPaper-root.MuiDrawer-paper,
	.drawer-register-form .MuiPaper-root.MuiDrawer-paper{
		width: 30%;
		border-radius: 20px;
		position: relative;
		flex: none;
	}

	.MuiDrawer-root.MuiDrawer-modal.drawer-login-form,
	.MuiDrawer-root.MuiDrawer-modal.drawer-register-form {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		align-content: center;
		justify-content: center;
		align-items: center;
	}
}