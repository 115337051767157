html {
    height: 94% !important;
    box-sizing: border-box;
}
body{
    /* background-image: none; */
    position: relative;
    margin: 0;
    padding-bottom: 48px;
    min-height: 100%;
}
.container-contact-form {
    display: flex;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: #fff;
    
}

p.text-local-expert {
    color: #878787;
    font-size: 20px;
    padding: 5px;
    margin: 30px auto 0;
    width: 300px;
}
/* p.text-local-expert strong{
    color: #004A63;
} */

img.img-local-expert{
   width: 170px;
   margin: 0 auto;
}

p.description-local-expert {
    color: #A9A9A9;
    padding: 5px 34px;
    font-size: 16px;
    width: 300px;
    margin: 0 auto;
    text-align: justify;
}
.cards-selection {
    width: 300px;
    margin: 0 auto;
}
.MuiCard-root{
    margin-bottom: 10px;
}
.MuiCardContent-root{
    padding: 6px 12px !important;
}
.contact-info {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.check-contact-info {
    width: 30%;
    height: 50px;
    display: flex;
    align-items: center;
}

.check-contact-info img {
    width: 40px;
    height: 40px;
    display: inline-block;
}

.input-contact-info {
    width: 70%;
    display: inline-block;
    height: 50px;
}
.input-contact-info.large{
    width: 100%;
}

.check-contact-info .MuiCheckbox-root {
    padding: 4px;
}

/* .input-contact-info label {
    color: #004A63;
    margin-left: 0;
    font-size: 18px;
    display: block;
    text-align: left;
    font-weight: 500;
} */

.input-contact-info input[type="text"],
.input-contact-info input[type="number"],
.input-contact-info input[type="email"] {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    background-color: #DADADA;
    border: 1px solid #CACACA;
    width: calc(100% - 14px);
    height: 20px;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 16px;
}
.input-contact-info input[type="text"].disabled,
.input-contact-info input[type="number"].disabled,
.input-contact-info input[type="email"].disabled {
    background-color: #adadad;
    opacity: 1;
}

/* .input-contact-info input[type="text"]:focus,
.input-contact-info input[type="number"]:focus,
.input-contact-info input[type="email"]:focus {
    outline: #004A63 auto 1px;
} */

.one-option-contact-form p{
    font-size: 12px;
    text-align: justify;
    color: #a9aeb7;
    margin: 4px 2px;
}

.one-option-contact-form, .terms-contact-form {
    display: flex;
    width: 300px;
    margin: 0 auto;
}
.one-option-contact-form{
    flex-direction: column;
}

.one-option-contact-form p.sms-notify {
    color: #FF7415;
  }

.check-terms {
    display: inline-block;
    width: 42px;
}

.terms {
    display: inline-block;
    width: 90%;
    font-size: 12px;
    text-align: justify;
    margin: 0;
}

/* .container-contact-form button {
    width: 120px;
    height: 33px;
    background-color: #FF7415;
    border: 1px solid #FF7415;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    padding: 4px 10px;
    font-weight: 500;
    margin-top: 12px;
} */


/*Telephone*/
.input-contact-info .react-tel-input .country-list {
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
}

.input-contact-info .react-tel-input .form-control {
    width: calc(100% - 0px);
    height: 26px;
    border: 1px solid #CACACA;
    background-color: #DADADA;
    border-radius: 4px;
    font-size: 16px;
    color: #000;
    top: 22px;
    padding-left: 42px;
}

.input-contact-info .react-tel-input .form-control::placeholder {
    color: #DADADA;
}

/* .input-contact-info .react-tel-input .special-label {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    text-align: left;
} */

.input-contact-info .react-tel-input .flag-dropdown {
    top: 22px;
    height: 26px;
    background-color: #dadada;
    border-right: 2px solid #fff;
}

.input-contact-info .disabled.react-tel-input .form-control {
    background-color: #adadad;
    opacity: 1;
    color: rgba(0,0,0,0.4);
}

.input-contact-info .disabled.react-tel-input .flag-dropdown {
    background-color: #adadad;
    border-right: 2px solid #fff;
}
.input-contact-info .disabled.react-tel-input .flag-dropdown .flag {
    opacity: 0.4;
}

.input-contact-info .disabled.react-tel-input .form-control::placeholder {
    color: #adadad;
}

.input-contact-info .react-tel-input .country-list .country {
    text-align: left;
    padding: 7px 20px;
}

.input-contact-info .react-tel-input .country-list .search {
    background-color: #f1f1f1;
    padding: 20px 10px 10px 10px;
}

.input-contact-info .react-tel-input .country-list .search-box {
    border: 1px solid #cacaca;
    width: 90%;
    margin: 0;
    box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    -webkit-appearance: none;
}