@use 'core';

.container-contact-form {
	.rnc__base{
		.rnc__notification-title,
		.rnc__notification-message{
				font-family: core.$font-stack;
		}
		// .rnc__notification-timer{}
	}
	.btn-language{
		position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    margin-top: 0;
    padding: 12px;
	}
}