.myexp-card-item{
    margin: 0 12px;
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #878787;
}
.myexp-card-item.MuiPaper-root {
    padding: 10px;
    color: #878787;
}
.myexp-card-item p{
    font-size: 12px;
}
.myexp-card-item p.myexp-title {
    font-size: 14px;
}
.myexp-card-item p.myexp-title strong {
    font-size: 18px;
}
/* .myexp-card-item .myexp-btn-survey {
    width: auto;
    height: 18px;
    background-color: #ff7415;
    border: 1px solid #ff7415;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    padding: 4px 10px;
    font-weight: 500;
    margin: 8px auto 0;
    text-decoration: none;
    display: block;
    max-width: 110px;
} */
/* .myexp-card-item.myexp-p-thankyou p {
    font-size: 16px;
}
.myexp-card-item.myexp-p-thankyou strong {
    color: #ff7415;
} */
#container-ta {
    width: 240px;
    margin: auto;
    border: 2px solid #90dabf;
    padding: 4px;
}
#container-ta .ta-link-op{
    border: none;
    font-size: 12px;
    font-weight: 700;
    color: #000;
    text-decoration: underline;
}
#container-ta .ta-logo img {
    height: 30px;
}
#container-ta .ta-rating-label{
    font: normal 10px Trip Sans VF,Trip Sans,Arial,sans-serif;
    color: #34e0a1;
    display: block;
}
#container-ta .ta-form textarea{
    font: normal 11px Trip Sans VF,Trip Sans,Arial,sans-serif;
    border: 1px solid #000;
    padding: 3px;
    width: 210px;
    height: 60px;
    min-width: 210px;
    max-width: 210px;
    min-height: 60px;
    max-height: 60px;
    overflow: auto;
    word-wrap: break-word;
    float: none;
    margin: 0;
}
#container-ta .ta-form button {
    border: 1px solid #000;
    font: normal 13px Trip Sans VF,Trip Sans,Arial,sans-serif;
    color: #fff;
    background-color: #000;
    border-radius: 5px;
    margin: 10px auto;
    padding: 2px 10px;
    white-space: nowrap;
    cursor: pointer;
    float: none;
    width: auto;
    height: 27px;
    display: block;
}
.MuiRating-iconEmpty{
    color: #fff !important;
}