.contact-container-cards{
    width: 95% !important;
    margin: auto !important;
}
.contact-card-item{
    height: 100%;
    text-align: center;
}
img.contact-card-img {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}
.contact-card-title{
    display: block;
    font-family: 'Roboto', 'Times New Roman', Times, serif;
    font-size: 14px;
    font-weight: bold;
    color: #f37020;
    margin-bottom: 5px;
}
.contact-card-links{
    text-align: left;
    width: 70px;
    margin: auto;
    font-family: 'Roboto', 'Times New Roman', Times, serif;
    font-size: 13px;
}
.contact-card-links p{
    font-size: 13px;
}
h1,h2,h3,h4,h5,h6,li,p{
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
ul, ol {
  padding-left: 20px;
}
li, p{
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.00938em;
}
h2{
	margin: 0;
	color: #eb6506;
	font-weight: bold;
}
h3{
	margin: 0 0 10px 0;
	font-weight: bold;
}
/* Contact Us */
.contact-card-links a {
	color: #505050;
    font-weight: bold;
    padding: 2px 0;
    display: block;
}
h5.title-social{
	margin: 8px;
	text-align: center;
	font-size: 16px;
	color: #eb6506;
}
a.title-web{
	margin: -5px 0 8px 0;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    color: #f37021;
    font-family: 'Roboto';
    display: block;
}
h5.title-contact{
	text-align: left;
	font-size: 20px;
    font-weight: 500;
}
a.icon-social {
	margin: 2px;
	display: inline-block;
}
a.btn-quality {
	width: 100%;
	background-color: #eb6506;
	margin: 6px 0;
	border-radius: 20px;
	text-align: center;
}
a.btn-quality .MuiButton-label{
	color: #fff;
}
a.btn-quality .MuiButton-startIcon img{
	width: 40px;
}
.background-orange{
	background-color: #EB6506;
}
.background-orange h5.title-social{
	color: #FFF;
}
.social-grid{
    text-align: center;
}
.social-grid span {
    display: block;
    font-family: 'Roboto';
    font-size: 12px;
    color: #b1b1b1;
}
.container-social{
    text-align: center;
}
.container-btn-call {
    display: block;
    text-align: center;
    margin: auto;
    padding: 14px 0;
}

.chat-rep {
    display: flex;
    align-items: center;
    width: 170px;
    margin: 0 auto;
    text-decoration: none;
    background-color: transparent;
    border: none;
    font: 400 14px 'Roboto';
    cursor: pointer;
}

.chat-rep:focus {
    outline: none;
}

.container-btn-call .chat-rep img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.container-btn-call .chat-rep span {
    color: #505050;
    text-decoration: solid;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-shadow: 2px 2px 3px #fff;
    display: inline-block;
    margin: auto;
}