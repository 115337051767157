@media (min-width: 3000px)
{
    #root {
        /* max-width: 768px; */
        display: flex;
        align-items: center;
        margin: 0 auto;
    }

    #root .rnc__base {
        width: 40%;
    }

    header#main-header {
        max-width: 768px;
        right: auto;
        margin: 0 auto;
    }

    .container-chat {
        max-width: 768px;
        margin: 0 auto;
    }
    .back-to-home{
        left: auto !important;
    }

    .container-login {
        flex-grow: 1;
    }
    
    .container-login .btn-language {
        right: calc(50% - 384px) !important;
    }
    
}