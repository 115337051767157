#web-messenger-container #messenger-button,
#messenger-button .messenger-button-icon,
#messenger-button .messenger-button-icon img {
    display: none !important;
}
/* iframe#web-messenger-container{
    bottom: 0px;
    right: 0px;
} */
.container-chat {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
}
.container-chat2 {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.5rem;
}
.container-login > .container-chat{
    position: fixed;
}
.container-chat .container-btn-call-chat {
    width: 50%;
    display: inline-block;
}
.container-chat2 .container-btn-call-chat {
    width: 50%;
    display: inline-block;
}
.container-chat a {
    display: flex;
    align-items:center;
    width: 170px;
    margin: 0 auto;
    text-decoration: none;
    font: 400 14px 'Roboto';
}
.container-chat2 a {
    display: flex;
    align-items:center;
    width: 170px;
    margin: 0 auto;
    text-decoration: none;
    font: 400 14px 'Roboto';
}

.container-chat a span {
    color: #505050;
    text-decoration: solid;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-shadow: 2px 2px 3px #fff;
    width: 130px;
    margin-left: 5px;
}
.container-chat2 a span {
    color: #505050;
    text-decoration: solid;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-shadow: 2px 2px 3px #fff;
    width: 130px;
    margin-left: 5px;
}
.container-checkin .container-chat {
    position: block;
    width: 100%;
}
.container-checkin .container-chat2 {
    position: block;
    width: 100%;
}