.thumbs-wrapper.axis-vertical {
    display: none;
}
.carousel-root{
    margin-top: 16px;
}
.carousel .slide{
    background-color: #FFF;
}
.carousel .slider-wrapper{
    min-height: 340px;
}
.carousel .slide img{
    width: 95%;
}
.carousel .control-dots .dot{
    box-shadow: 1px 1px 2px rgb(191 191 191 / 90%);
    background: #bfbfbf;
}

p.carousel-status {
    display: none;
}
a.link-banner {
    width: 100%;
    height: 100%;
    display: block;
}