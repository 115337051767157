@use 'core';

.container-checkin{
    .rnc__base{
        .rnc__notification-title,
        .rnc__notification-message{
            font-family: core.$font-stack;
        }
        // .rnc__notification-timer{}
    }
    .booking, .title, p.description{
        font-family: core.$font-stack;
        color: core.$gray-mild-color;
        margin-block: 0;
        margin-inline: 0;
        margin-left: 25px;
    }
    .booking{
        margin-top: 20px;
        color: #000;
        font-weight: 600;
    }
    .title{
        margin-top: 30px;
        font-size: 36px;
        color: core.$primary-color;
    }
    p.description{
        font-size: 16px;
        margin-bottom: 20px;
    }
    .container-search, .container-booking-lines {
        padding: 0 20px;
    }
    .container-search{
        margin-top: -10px;
        .MuiFormControl-root {
            width: 100%;
            margin-bottom: 20px;
            label.Mui-focused {
                color: core.$primary-color;
            }
            .MuiInput-root:after{
                border-bottom: 2px solid core.$primary-color;
            }
        }
    }
    .wizard-control {
        width: 100%;
        position: relative;
        .MuiButtonBase-root.MuiButton-root.prev,
        .MuiButtonBase-root.MuiButton-root.next{
            position: absolute;
            top: 0;
            padding: 8px 12px;
            border-radius: 4px;
        }
        .MuiButtonBase-root.MuiButton-root.prev{
            left: 20px;
            color: #5d6368;
        }
        .MuiButtonBase-root.MuiButton-root.next{
            right: 20px;
            color: #f67014;
        }
    }
}
.MuiPaper-root.MuiCard-root.card-main {
    margin-bottom: 20px;
    .MuiCardContent-root{
        padding: 16px 8px;
    }
    .MuiCardActionArea-root{
        display: block;
    }
    .card-header {
        position: relative;
        top: 0;
        width: 100%;
        margin-left: 0;
        font-size: 14px;
        font-family: core.$font-stack;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        .pax-name, .date{
            // text-transform: capitalize;
            line-height: 15px;
        }
        .pax-name{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            width: 100%;
            .pax{
                display: flex;
                align-items: center;
                padding-bottom: 4px;
                mark {
                    color: #fff;
                }
            }
            .btn-show-name{
                position: absolute;
                top: 0;
                right: 0;
                padding: 0;
                .MuiSvgIcon-root{
                    margin: 0;
                }
            }
        }
        .date{
            min-width: 74px;
            .MuiSvgIcon-root{
                margin-left: 4px;
            }
        }
        .MuiSvgIcon-root{
            width: 16px;
            height: 16px;
            color: core.$gray-bold-color;
            margin-right: 4px;
        }
    }
    .card-content {
        margin-top: -4px;
        .image {
            display: inline-block;
            width: 50px;
            height: 50px;
            img {
                width: 40px;
                height: 40px;
                padding: 16px 0;
            }
        }
        .icon-radio {
            display: inline-block;
            width: 40px;
            height: 70px;
            .MuiSvgIcon-root{
                width: 30px;
                height: 30px;
                padding: 25px 0;
                color: core.$gray-mild-color;
            }
        }
        .content-info {
            position: relative;
            display: inline-block;
            width: calc(100% - 70px);
            padding-left: 6px;
            .from, .to {
                font-size: 14px;
                font-weight: bold;
                color: #000;
                position: relative;
            }
            .from::before, .to::before {
                content: '';
                display: block;
                position: absolute;
                left: -12px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #fff;
                border: 2px solid core.$primary-color;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
            .from::before {
                top: 5px;
            }
            .to::before {
                bottom: 5px;
            }
            .flight-info, .tranfer-info {
                display: flex;
                align-items: center;
                color: #5d6368;
                font-size: 12px;
                .MuiSvgIcon-root{
                    color: #5d6368;
                    width: 18px;
                    height: 18px;
                    margin-right: 4px;
                    margin-left: 4px;
                }
            }
        }
        .content-info::before {
            content: '';
            position: absolute;
            top: 7px;
            bottom: -6px;
            left: -4px;
            width: 2px;
            height: -webkit-calc(100% - 32px);
            height: calc(100% - 32px);
            background: core.$primary-color;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }
    }
}
.MuiPaper-root.MuiCard-root.card-main-exc{
    .MuiCardContent-root {
        padding: 16px 8px 6px;
    }
    .card-exc-content{
        min-height: 40px;
        .content-info{
            width: calc(100% - 90px);
        }
        .image{
            width: 50px;
            height: 40px;
            img{
                padding: 0;
            }
        }
    }
}
.MuiPaper-root.MuiCard-root.card-main.card-main-upg{
    border: 2px solid #f67720;
    .MuiCardContent-root {
        padding: 0;
    }
    .card-header {
        background-color: #f67720;
        width: calc(100% - 8px);
        text-align: center;
        font-size: 12px;
        padding: 4px;
        color: #fff;
    }
    .card-content{
        margin-bottom: 20px;
        .image{
            display: block;
            width: 60%;
            height: 45%;
            margin: auto;
            img{
                width: 100%;
                height: 100%;
                padding: 20px 0 6px;
            }
        }
        .title{
            text-align: center;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .MuiCardActions-root{
        padding: 0 8px;
        position: relative;
    }
    .MuiButtonBase-root.MuiIconButton-root{
        padding: 0;
        position: absolute;
        bottom: 0;
        right: 4px;
    }
    .collapse-list{
        margin-bottom: 5px;
        ul{
            display: block;
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 25px;
            padding-inline-end: 5px;
            font-family: core.$font-stack;
            font-size: 12px;
            li{
                margin-top: -5px;
                margin-left: 0px;
            }
            li::marker{
                color: core.$primary-color;
                font-size: 20px;
                text-align: left !important;
                text-align-last: left!important;
            }
        }
    }
}
.MuiPaper-root.MuiCard-root.card-main.non{
    .MuiCardContent-root{
        padding: 0 8px;
    }
    .card-content {
        .icon-radio {
            height: 40px;
            .MuiSvgIcon-root{
                padding: 4px 0;
            }
        }
        .content-info {
            .not-trf{
                padding: 0;
                font-family: Roboto;
                font-size: 14px;
                font-weight: bold;
                color: #000;
                position: absolute;
                top: -26px;
            }
        }
    }
}
.MuiPaper-root.MuiCard-root.card-main.selected {
    border: 2px solid core.$primary-color;
    box-shadow: none;
    .card-content {
        .icon-radio {
            .MuiSvgIcon-root{
                color: core.$primary-color;
            }
        }
    }
}
.MuiPaper-root.MuiCard-root.card-main.error {
    border: 2px solid core.$warning-color;
    .card-header {
        .date{
            .icon-pickup{
                color: core.$warning-color;
            }
        }
    }
}
.container-checkin.container-success-form,
.container-checkin.container-checkin-mytrip {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    .container-qr {
        position: relative;
        flex-grow: 1;
        background-color: core.$primary-color;
        height: 50vh;
        width: 100%;
        .title, p.description{
            color: #ffF;
            text-align: center;
            margin: 0;
        }
        .title{
            margin-top: 30px;
        }
        .main-qr {
            display: grid;
            background-color: #fff;
            width: 250px;
            height: 250px;
            margin: 20px auto;
            padding: 0px;
            justify-content: center;
            align-content: center;
            border-radius: 50%;
            img{
                width: 150px;
                height: 150px;
            }
        }
    }
    .container-info {
        flex-grow: 1;
        height: 50vh;
        width: 100%;
        background-color: #fff;
        .MuiGrid-container {
            width: calc(100% - 40px);
            margin: auto;
            .MuiGrid-root.MuiGrid-item {
                margin: 10px 0;
            }
        }
        .tag-label, .tag-input{
            font-family: core.$font-stack;
            font-weight: 500;
        }
        .tag-label {
            font-size: 12px;
            color: core.$gray-mild-color;
        }
        .tag-input {
            font-size: 16px;
            color: #000;
        }
    }
    .wizard-control{
        bottom: 42px;
    }
}
.container-checkin.container-checkin-mytrip {
    .container-qr{
        .title{
            margin-top: 0;
        }
        p.description{
            margin-top: 30px;
        }
        button.MuiButtonBase-root.MuiIconButton-root {
            position: absolute;
            color: #fff;
        }
        .icon-back-to {
            top: 8px;
            left: 8px;
        }
        .icon-download {
            bottom: 8px;
            left: 8px;
        }
    }
}
@media (min-width: 1024px)
{
	.container-checkin{
		width: 100%;
		margin: auto;
        .rnc__base,
        .notifications-component{
            width: 40%;
        }
		.wizard-control{
			position: absolute;
			bottom: 50px;
			width: 40%;
		}
	}
}